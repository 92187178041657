// Generated by Framer (013b13c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const serializationHash = "framer-mF96D"

const variantClassNames = {U9IS5HbtX: "framer-v-ul5qem"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({background, color, height, id, showVertical, width, ...props}) => { return {...props, CgCxwDz_B: showVertical ?? props.CgCxwDz_B ?? true, CquvwTJCF: color ?? props.CquvwTJCF ?? "rgb(255, 255, 255)", L_MXIE6eA: background ?? props.L_MXIE6eA ?? "var(--token-88d5059b-bc5d-4e0a-ad79-b21e9a2c4948, rgb(10, 10, 10))"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;color?: string;background?: string;showVertical?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, CquvwTJCF, L_MXIE6eA, CgCxwDz_B, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "U9IS5HbtX", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-ul5qem", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"U9IS5HbtX"} ref={refBinding} style={{backgroundColor: L_MXIE6eA, borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, ...style}}>{CgCxwDz_B && (<motion.div className={"framer-1f2k6fv"} data-framer-name={"V"} layoutDependency={layoutDependency} layoutId={"QjeqJHeyd"} style={{backgroundColor: CquvwTJCF}}/>)}<motion.div className={"framer-19zm8hd"} data-framer-name={"H"} layoutDependency={layoutDependency} layoutId={"IUdgccIUF"} style={{backgroundColor: CquvwTJCF, rotate: 90}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mF96D.framer-5fz3i3, .framer-mF96D .framer-5fz3i3 { display: block; }", ".framer-mF96D.framer-ul5qem { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 18px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 18px; will-change: var(--framer-will-change-override, transform); }", ".framer-mF96D .framer-1f2k6fv, .framer-mF96D .framer-19zm8hd { flex: none; height: 8px; left: calc(50.00000000000002% - 2px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 8px / 2); width: 2px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-mF96D.framer-ul5qem { gap: 0px; } .framer-mF96D.framer-ul5qem > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-mF96D.framer-ul5qem > :first-child { margin-left: 0px; } .framer-mF96D.framer-ul5qem > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 18
 * @framerIntrinsicWidth 18
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"CquvwTJCF":"color","L_MXIE6eA":"background","CgCxwDz_B":"showVertical"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerovCH22Xmz: React.ComponentType<Props> = withCSS(Component, css, "framer-mF96D") as typeof Component;
export default FramerovCH22Xmz;

FramerovCH22Xmz.displayName = "Plus icon small";

FramerovCH22Xmz.defaultProps = {height: 18, width: 18};

addPropertyControls(FramerovCH22Xmz, {CquvwTJCF: {defaultValue: "rgb(255, 255, 255)", title: "Color", type: ControlType.Color}, L_MXIE6eA: {defaultValue: "var(--token-88d5059b-bc5d-4e0a-ad79-b21e9a2c4948, rgb(10, 10, 10)) /* {\"name\":\"Black\"} */", title: "Background", type: ControlType.Color}, CgCxwDz_B: {defaultValue: true, title: "Show vertical?", type: ControlType.Boolean}} as any)

addFonts(FramerovCH22Xmz, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})